import {Component, OnInit} from '@angular/core';
import {isAdminOrManager} from '../../domain/utils/utils';
// import PerfectScrollbar from 'perfect-scrollbar';

declare const $: any;

// Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}

// Menu Items
export const ADMIN_ROUTES: RouteInfo[] = [{
    path: '/admin',
    title: 'Admin',
    type: 'sub',
    icontype: 'apps',
    collapse: 'admin',
    children: [
        {path: 'accounts', title: 'Conturi experti', ab: 'CE'},
        {path: 'locations', title: 'Localitati', ab: 'L'},
    ]
}, {
    path: '/admin',
    title: 'Rapoarte admin',
    type: 'sub',
    icontype: 'content_paste',
    collapse: 'adminReports',
    children: [
        {path: 'attendance-report', title: 'Centralizator pontaj', ab: 'CP'},
        {path: 'trip-report', title: 'Centralizator deplasari', ab: 'CD'},
        {path: 'activity-description-report', title: 'Descriere pe scurt activitate', ab: 'DA'},
        {path: 'activity-planning-report', title: 'Planificare activitati', ab: 'PA'},
        {path: 'statistic', title: 'Statistica', ab: 'S'}
    ]
}, {
    path: '/manager',
    title: 'Administrare',
    type: 'sub',
    icontype: 'place',
    collapse: 'manage',
    children: [
        {path: 'profile', title: 'Profilul meu', ab: 'PM'},
        {path: 'timesheet', title: 'Pontaj', ab: 'P'},
        {path: 'report', title: 'Raport de activitate', ab: 'RA'},
    ]
}, {
    path: '/check',
    title: 'Verificari',
    type: 'sub',
    icontype: 'check',
    collapse: 'checks',
    children: [
        {path: 'timesheet', title: 'Verificare pontaj', ab: 'VP'},
        {path: 'report', title: 'Verificare raport', ab: 'VR'},
    ]
}, {
    path: '/beneficiary',
    title: 'Gestiune Beneficiari',
    type: 'sub',
    icontype: 'people',
    collapse: 'beneficiary',
    children: [
        {path: 'list', title: 'Beneficiari', ab: 'B'},
        {path: 'export-beneficiary-annex-in', title: 'Anexe beneficiari intrare', ab: 'B'},
        {path: 'export-beneficiary-annex-out', title: 'Anexe beneficiari iesire', ab: 'B'},
    ]
}, {
    path: '/beneficiary',
    title: 'Experti',
    type: 'sub',
    icontype: 'star',
    collapse: 'experts',
    children: [
        {path: 'experts-list', title: 'Experti beneficiari', ab: 'B'},
        {path: 'course-list', title: 'Cursuri', ab: 'B'},
        {path: 'export-beneficiary-statistics', title: 'Export statistica beneficiari', ab: 'B'},
        {path: 'expert-tools', title: 'Instrumente experti', ab: 'B'}
    ]
}];

export const USER_ROUTES: RouteInfo[] = [{
    path: '/manager',
    title: 'Administrare',
    type: 'sub',
    icontype: 'place',
    collapse: 'manage',
    children: [
        {path: 'profile', title: 'Profilul meu', ab: 'PM'},
        {path: 'timesheet', title: 'Pontaj', ab: 'P'},
        {path: 'report', title: 'Raport de activitate', ab: 'RA'},
    ]
}, {
    path: '/beneficiary',
    title: 'Gestiune Beneficiari',
    type: 'sub',
    icontype: 'people',
    collapse: 'beneficiary',
    children: [
        {path: 'list', title: 'Beneficiari', ab: 'B'},
    ]
}, {
    path: '/beneficiary',
    title: 'Experti',
    type: 'sub',
    icontype: 'star',
    collapse: 'experts',
    children: [
        {path: 'experts-list', title: 'Experti beneficiari', ab: 'B'},
        {path: 'course-list', title: 'Cursuri', ab: 'B'},
        {path: 'export-beneficiary-statistics', title: 'Export statistica beneficiari', ab: 'B'},
        {path: 'expert-tools', title: 'Instrumente experti', ab: 'B'},
    ]
}];

@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.css']
})
export class SidenavComponent implements OnInit {
    public menuItems: any[];
    ps: any;
    user: string;

    isMobileMenu() {
        return $(window).width() <= 991;
    }

    ngOnInit() {
        if (isAdminOrManager()) {
            this.menuItems = ADMIN_ROUTES.filter(menuItem => menuItem);
        } else {
            this.menuItems = USER_ROUTES.filter(menuItem => menuItem);
        }
        console.log(this.menuItems);
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
            this.user = localStorage.getItem('name');
            // this.ps = new PerfectScrollbar(elemSidebar);
        }
    }

    updatePS(): void {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            // this.ps.update();
        }
    }

    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }

    logout() {
        localStorage.clear();
        location.reload();
    }
}

