<div class="wrapper">
    <div class="sidebar" data-color="white" data-background-color="red">
        <app-sidenav></app-sidenav>
    </div>
    <div class="main-panel">
        <app-navbar></app-navbar>
        <router-outlet></router-outlet>
        <div *ngIf="!isMap()">
            <app-footer-cmp></app-footer-cmp>
        </div>
    </div>
</div>
