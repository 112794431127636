import {HttpHeaders} from '@angular/common/http';
import {Role} from '../enums/role';
import {Time} from '@angular/common';
import {ProjectType} from '../project-type';
import {ResidenceType} from '../enums/residence-type';
import {Study} from '../enums/study';
import {OccupationType} from '../enums/occupation-type';
import {CourseType} from '../enums/course-type';


export function isNullOrUndefined(value: any): boolean {
    return value === undefined || value === null;
}

export function isDefined(value: any): boolean {
    return !isNullOrUndefined(value);
}

export function isLoggedIn(): boolean {
    const exp = localStorage.getItem('exp');
    if (exp !== null) {
        return Date.now() < new Date(0).setUTCSeconds(Number(exp));
    }
    return false;
}

export function isNotLoggedIn(): boolean {
    return !isLoggedIn();
}

export function getLoggedInUserRole(): Role {
    const role = localStorage.getItem('role');
    switch (role) {
        case 'USER' : return Role.USER;
        case 'ADMIN' : return Role.ADMIN;
        case 'MANAGER' : return Role.MANAGER;
    }
    return Role.NO_ROLE;
}

export function isClient(): boolean {
    const role = localStorage.getItem('role');
    return role === 'CLIENT';
}

export function isAdminOrManager(): boolean {
    const role = localStorage.getItem('role');
    return role === 'ADMIN' || role === 'MANAGER';
}

export function isNotAdminOrManager(): boolean {
    return !isAdminOrManager();
}

export function getUserId(): string {
    const id = localStorage.getItem('user_id').toString();
    return isDefined(id) ? id : '';
}

export function getRequestHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    const token = localStorage.getItem('token');
    headers = headers.set('da', 'da');
    headers = headers.set('Authorization', 'Bearer ' + token);
    return headers;
}

export function getFileRequestHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    const token = localStorage.getItem('token');
    headers = headers.set('Authorization', 'Bearer ' + token);
    return headers;
}

export function getDefaultFile(): File {
    return new File([''], 'filename');
}

export function initFileArray(): File[] {
    return [] as File[];
}

export function extractTime(time: string): Time {
    const [h, m] = time.split(':');
    return {hours: +h, minutes: +m};
}

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
}

export function compareIds(o1: any, o2: any) {
    return o1.id === o2.id;
}

export function compareResidence(o1: any, o2: any) {
    return o1 === ResidenceType[o2];
}

export function compareCourseType(o1: any, o2: any) {
    return o1 === CourseType[o2];
}

export function compareStudy(o1: any, o2: any) {
    return o1 === Study[o2];
}

export function compareOccupation(o1: any, o2: any) {
    return o1 === OccupationType[o2];
}

export const categoryComparator = (o1: any, o2: any) => o1 === o2;

export const MONTH_FORMATS = {
    parse: {
        dateInput: 'MM/YYYY',
    },
    display: {
        dateInput: 'MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

export function getColorForProject(projectType: ProjectType): string {
    const x = ProjectType[projectType];
    if (+x === 1) { return 'orange'; }
    if (+x === 2) { return 'purple'; }
    return '';
}

export function scrollTo(el: Element): void {
    if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
}

export function scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-  invalid[formControlName]');
    this.scrollTo(firstElementWithError);
}

export function getSupportNeedsTitles(): string[] {
    return [
        'Suport din partea angajatorului',
        'Suport din partea consilierului',
        'Suport familie/ grup apartenenta',
        'Completare studii',
        'Suport material',
        'Suport financiar'
    ];
}
