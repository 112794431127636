

<div class="logo">
    <a class="simple-text logo-mini">
        <div class="logo-img">
            <img src="/assets/img/logo_250x250.png"/>
        </div>
    </a>
    <a href="https://www.creative-tim.com" class="simple-text logo-normal">
        RECON
    </a>
</div>


<div class="sidebar-wrapper">

    <div class="user">
        <div class="photo">
            <img src="./assets/img/faces/card-profile1-square.jpg" />
        </div>
        <div class="user-info">
            <a data-toggle="collapse" href="#collapseExample" class="collapsed">
                        <span>
                            {{user}}
                        </span>
            </a>
        </div>
    </div>
    <ul class="nav" id="accordion">
        <li routerLinkActive="active" *ngFor="let menuitem of menuItems" class="nav-item">
            <!--If is a single link-->
            <a [routerLink]="[menuitem.path]" *ngIf="menuitem.type === 'link'" class="nav-link">
                <i class="material-icons">{{menuitem.icontype}}</i>
                <p>{{menuitem.title}}</p>
            </a>
            <a data-toggle="collapse" data-target="#{{menuitem.collapse}}" href="#{{menuitem.collapse}}" *ngIf="menuitem.type === 'sub'" (click)="updatePS()" class="nav-link">
                <i class="material-icons">{{menuitem.icontype}}</i>
                <p>{{menuitem.title}}<b class="caret"></b></p>
            </a>

            <!--Display the submenu items-->
            <div id="{{menuitem.collapse}}" data-parent="#accordion" class="collapse" *ngIf="menuitem.type === 'sub'">
                <ul class="nav">
                    <li routerLinkActive="active" *ngFor="let childitem of menuitem.children" class="nav-item">
                        <a [routerLink]="[menuitem.path, childitem.path]" class="nav-link">
                            <span class="sidebar-normal">{{childitem.title}}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </li>
        <li class="nav-item">
            <a (click)="logout()" class="nav-link">
                <i class="material-icons">block</i>
                <p>Logout</p>
            </a>
        </li>
    </ul>

</div>
