import {Pipe, PipeTransform} from '@angular/core';
import {Time} from '@angular/common';

@Pipe({
  name: 'timePipe'
})
export class TimePipe implements PipeTransform {

  transform(value: Time, ...args: unknown[]): any {
    return `${value.hours.toString().padStart(2, '0')}:${value.minutes.toString().padStart(2, '0')}`;
  }
}
