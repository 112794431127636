import {Routes} from '@angular/router';

import {AdminLayoutComponent} from './layouts/admin/admin-layout.component';
import {AuthLayoutComponent} from './layouts/auth/auth-layout.component';
import {HomepageComponent} from './prod/homepage/homepage.component';
import {MainPageComponent} from './prod/main-page/main-page.component';
import {Role} from './domain/enums/role';
import {AuthGuard} from './prod/guards/auth-guard';
import {LoginComponent} from './prod/login/login.component';
import {LoginGuard} from './prod/guards/login-guard';

export const AppRoutes: Routes = [
    {
        path: '',
        redirectTo: '/manager/profile',
        pathMatch: 'full'
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'mainpage',
        component: MainPageComponent
    },
    {
        path: 'adminlayout',
        component: AdminLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
            }, {
                path: 'components',
                loadChildren: () => import('./components/components.module').then(m => m.ComponentsModule)
            }, {
                path: 'forms',
                loadChildren: () => import('./forms/forms.module').then(m => m.Forms)
            }, {
                path: 'tables',
                loadChildren: () => import('./tables/tables.module').then(m => m.TablesModule)
            }, {
                path: 'maps',
                loadChildren: () => import('./maps/maps.module').then(m => m.MapsModule)
            }, {
                path: 'widgets',
                loadChildren: () => import('./widgets/widgets.module').then(m => m.WidgetsModule)
            }, {
                path: 'charts',
                loadChildren: () => import('./charts/charts.module').then(m => m.ChartsModule)
            }, {
                path: 'calendar',
                loadChildren: () => import('./calendar/calendar.module').then(m => m.CalendarModule)
            }, {
                path: '',
                loadChildren: () => import('./userpage/user.module').then(m => m.UserModule)
            }, {
                path: '',
                loadChildren: () => import('./timeline/timeline.module').then(m => m.TimelineModule)
            }, {
                path: 'admin',
                loadChildren: () => import('./prod/admin/admin.module').then(m => m.AdminModule)
            }
        ]
    }, {
        path: '',
        component: HomepageComponent,
        children: [
            {
                path: 'admin',
                loadChildren: () => import('./prod/admin/admin.module').then(m => m.AdminModule),
            },
            {
                path: 'manager',
                loadChildren: () => import('./prod/manager/manager.module').then(m => m.ManagerModule)
            },
            {
                path: 'check',
                loadChildren: () => import('./prod/check/check.module').then(m => m.CheckModule)
            },
            {
                path: 'beneficiary',
                loadChildren: () => import('./prod/beneficiary/beneficiary.module').then(m => m.BeneficiaryModule)
            }
        ],
        canActivate: [LoginGuard]
    },
    {
        path: '',
        component: AuthLayoutComponent,
        children: [{
            path: 'pages',
            loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
        }]
    }
];
