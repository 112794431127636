import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {HttpClientModule} from '@angular/common/http';
import {CommonModule, DatePipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {MatNativeDateModule} from '@angular/material/core';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatMenuModule} from '@angular/material/menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTabsModule} from '@angular/material/tabs';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';

import {AppComponent} from './app.component';

import {SidebarModule} from './sidebar/sidebar.module';
import {FooterModule} from './shared/footer/footer.module';
import {NavbarModule} from './shared/navbar/navbar.module';
import {FixedpluginModule} from './shared/fixedplugin/fixedplugin.module';
import {AdminLayoutComponent} from './layouts/admin/admin-layout.component';
import {AuthLayoutComponent} from './layouts/auth/auth-layout.component';

import {AppRoutes} from './app.routing';

import {HomepageComponent} from './prod/homepage/homepage.component';
import {NavbarComponent} from './prod/navbar/navbar.component';
import {SidenavModule} from './prod/sidenav/sidenav.module';
import {LoginComponent} from './prod/login/login.component';
import {FileValueAccessorDirective} from './prod/directives/file-value-accessor.directive';
import {FileValidatorDirective} from './prod/directives/file-validator.directive';
import {FullCalendarModule} from '@fullcalendar/angular';
import {ExportComponentsModule} from './prod/export-components/export-components.module';
import {MonthDatePipe} from './prod/pipes/month-date.pipe';
import {ActivityPipe} from './prod/pipes/activity.pipe';
import {LocationPipe} from './prod/pipes/location.pipe';
import { NamePipe } from './prod/pipes/name.pipe';
import { StreetPipe } from './prod/pipes/street.pipe';
import {SafeUrlPipe} from './prod/pipes/url.pipe';
import { StudyPipe } from './prod/pipes/study.pipe';
import { OccupationPipe } from './prod/pipes/occupation.pipe';
import {CategoryPipe} from './prod/pipes/category.pipe';
import { ReportTimesheetComponent } from './prod/report-check/waiting-report/report-timesheet/report-timesheet.component';
import { FormAutoFocusDirective } from './prod/directives/form-auto-focus.directive';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {TimePipe} from './prod/pipes/time.pipe';
import { UserActivityCodePipe } from './prod/pipes/user-activity-code.pipe';
import {ToolPipe} from './prod/pipes/tool.pipe';
import {ActivityCodePipe} from './prod/pipes/activity-code.pipe';
import {BooleanPipe} from './prod/pipes/boolean.pipe';
import { CourseTypePipe } from './prod/pipes/course-type.pipe';
import { NameListPipePipe } from './prod/pipes/name-list-pipe.pipe';
import { SituationPipe } from './prod/pipes/situation.pipe';


@NgModule({
    exports: [
        MatAutocompleteModule,
        MatButtonToggleModule,
        MatCardModule,
        MatChipsModule,
        MatCheckboxModule,
        MatStepperModule,
        MatDialogModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatDatepickerModule,
        MatButtonModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatNativeDateModule,
        MonthDatePipe,
        ActivityPipe,
        TimePipe,
        LocationPipe,
        NamePipe,
        StreetPipe,
        SituationPipe,
        SafeUrlPipe,
        ToolPipe,
        StudyPipe,
        OccupationPipe,
        CategoryPipe,
        FormAutoFocusDirective,
        UserActivityCodePipe,
        ActivityCodePipe,
        BooleanPipe,
        CourseTypePipe,
        NameListPipePipe
    ],
    imports: [
        SidebarModule,
        SidenavModule,
        NavbarModule,
        RouterModule,
        FormsModule,
        FooterModule,
        FixedpluginModule,
        ReactiveFormsModule,
        NgbModule
    ],
    declarations: [
        LoginComponent,
        HomepageComponent,
        NavbarComponent,
        FileValueAccessorDirective,
        FileValidatorDirective,
        FormAutoFocusDirective,
        MonthDatePipe,
        ActivityPipe,
        LocationPipe,
        NamePipe,
        StreetPipe,
        SafeUrlPipe,
        ToolPipe,
        StudyPipe,
        OccupationPipe,
        CategoryPipe,
        TimePipe,
        ReportTimesheetComponent,
        FormAutoFocusDirective,
        UserActivityCodePipe,
        ActivityCodePipe,
        BooleanPipe,
        CourseTypePipe,
        NameListPipePipe,
        SituationPipe
    ]
})
export class MaterialModule {
}

@NgModule({
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        FormsModule,
        RouterModule.forRoot(AppRoutes, {
            useHash: true
        }),
        HttpClientModule,
        SidenavModule,
        MaterialModule,
        SidebarModule,
        NavbarModule,
        FooterModule,
        FixedpluginModule,
        FullCalendarModule,
    ],
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        AuthLayoutComponent,
    ],
    providers: [
        MatNativeDateModule,
        DatePipe,
        TimePipe
    ],
    exports: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
